<template>
  <div :style="themeCss">
    <div id="aw--c">
      <div id="aw-sl" style="font-size: 1em !important">
        <div v-if="videoInfo != undefined" :key="videoInfo.video_urls.dash_url" class="amplily-player">
          <div class="rnd-crns" :style="`min-height:${widgetHeight}px !important`">
            <AdPlayer
              :video_url="videoInfo.video_urls.ad_urls.ad_url"
              :audio_url="videoInfo.video_urls.ad_urls.audio_url"
              :assets_path="videoInfo.video_urls.ad_urls.assets_path"
              :poster_url="videoInfo.video_urls.poster_url"
            />
          </div>
        </div>
        <div v-else>
          {{ loadingmsg }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import "@/assets/_base.scss"
import { SingleVideoInfo } from "../models/SingleVideoInfo"
import setThemeData from "../services/setThemeData"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container, inject } from "tsyringe"
import AdPlayer from "@/components/shared/AdPlayer.vue"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import getCssVariables from "@/utils/getCssVariables"
export default defineComponent({
  components: {
    AdPlayer
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup(props) {
    const webinarId = ref(-1)
    const videoInfo = ref<SingleVideoInfo | null>(null)
    const loadingmsg = ref("Loading video...")
    const globalData = injectStrict(GlobalDataKey).value
    const widgetHeight = computed<number>(() => globalData.height)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    let logger = injectStrict(LoggerKey)
    return {
      logger,
      dataService,
      widgetHeight,
      themeCss,
      globalData,
      webinarId,
      videoInfo,
      loadingmsg
    }
  },
  created() {
    this.dataService.getData(this.fetchId).then(returnValue => {
      this.loadingmsg = "The video could not be loaded."
      if (returnValue == undefined) {
        this.logger.error(`Parmonic Widget error: Video not found`)
        return
      }

      this.videoInfo = returnValue as SingleVideoInfo
      this.webinarId = this.videoInfo.id
      setThemeData(this.videoInfo.theme_data, this.globalData)
    })
  }
})
</script>
<style scoped>
.amplily-player {
  width: 100% !important;
}
</style>
