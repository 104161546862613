import "reflect-metadata"
import AmplilySingle from "./single-widget.vue"
import AmplilyBodymovin from "./bodymovin-widget.vue"
import { AwOptions } from "../models/AwOptions"
import { exposeAmplilyWidget } from "@/services/setupAmplilyWidget"
import { createWebinarVueApp } from "./create-webinar-vueapp"
;(window as any).setupAmplilyShared = (options: AwOptions, layout: string, el: HTMLElement) => {
  const AmplilyWidget = layout == "single" ? AmplilySingle : AmplilyBodymovin
  createWebinarVueApp(AmplilyWidget, options, el)
}
exposeAmplilyWidget()
