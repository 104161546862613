<template>
  <div class="flex-box">
    <div class="pw-player-button pw-duration" v-html="durationString.value" :style="{ width: durationWidth }"></div>
    <div class="pw-player-seekbar">
      <input type="range" step="any" min="0" max="100" aria-label="Seek" ref="shakaSeeker" :style="seekbarCss.value" />
    </div>
    <div class="pw-player-button pw-duration" v-html="totalDurationString.value" ref="totalDurationElm"></div>
  </div>
</template>
<script lang="ts">
import { GlobalDataKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType, ref, Ref } from "vue"

export default defineComponent({
  props: {
    durationString: {
      type: Object as PropType<Ref<String>>,
      required: true
    },
    totalDurationString: {
      type: Object as PropType<Ref<String>>,
      required: true
    },
    seekbarCss: {
      type: Object as PropType<Ref<Object>>,
      required: true
    },
    onSeekerInput: { type: Function, required: true }
  },
  setup() {
    const shakaSeeker = ref<HTMLInputElement>()
    const totalDurationElm = ref<HTMLElement>()
    let durationWidth = ref(`auto !important`)
    return { shakaSeeker, totalDurationElm, durationWidth }
  },
  mounted() {
    this.seekerSetUp()
    this.durationWidth =
      this.totalDurationElm?.clientWidth ?? 0 > 0 ? `${this.totalDurationElm!.clientWidth}px !important` : `auto !important`
  },
  methods: {
    seekerSetUp() {
      if (!this.shakaSeeker) return //TODO throw or log error
      var self = this

      this.shakaSeeker.oninput = function () {
        self.onSeekerInput(self.shakaSeeker)
      }
    }
  }
})
</script>
