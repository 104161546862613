
import replaceclass from "@/services/replaceClass"
import lottie, { AnimationItem } from "lottie-web"
import { defineComponent, computed, ref, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import setUpDuration from "@/composables/shared/duration-handler"
import { setUpPlayBtn } from "@/composables/shared/play-button-handler"
import setUpFullScreen from "@/composables/shared/fullscreen-hander"
import setUpSeeker from "@/composables/shared/seekbar-handler"
import setUpDinemtionHandler from "@/composables/shared/dimention-handler"
import SetUpCaptionService from "@/composables/captions-handler"
//Components
import ControlsLeftComponent from "@/components/VideoPlayerComponents/ControlsLeftComponent.vue"
import ControlsRightComponent from "@/components/VideoPlayerComponents/ControlsRightComponent.vue"
import PlayButtonComponent from "@/components/VideoPlayerComponents/PlayButtonComponent.vue"
import SeekbarComponent from "@/components/AdPlayerComponents/SeekbarComponent.vue"
import PosterComponent from "@/components/VideoPlayerComponents/PosterComponent.vue"
let animation: AnimationItem
export default defineComponent({
  components: {
    ControlsLeftComponent,
    ControlsRightComponent,
    PlayButtonComponent,
    SeekbarComponent,
    PosterComponent
  },
  props: {
    video_url: String,
    audio_url: String,
    assets_path: String,
    poster_url: { type: String, required: false }
  },
  setup(props) {
    let showControls = ref(true)
    let audioPlayer = ref<HTMLAudioElement>()
    let shakaContainer = ref<HTMLElement>()
    let playerContainer = ref<HTMLElement>()
    let currentFrame = ref(0)
    let has_audio = computed(() => audioPlayer != null && props.audio_url != null)
    let volumeBar = ref<HTMLElement>()
    let playerBottomController = ref<HTMLElement>()
    let vbleftMargin = computed(() => {
      var val = 0
      if (playerBottomController.value) {
        val = playerBottomController.value?.clientWidth * 0.02 + 32
      }
      return val
    })
    let vbbottomMargin = ref(0)
    let durationObj = setUpDuration(0)
    let { seekbarCss } = setUpSeeker(durationObj.currentTime, durationObj.dur)
    let getseekbarCss = () => seekbarCss
    const globalData = injectStrict(GlobalDataKey).value
    let { isplaying, playbtnString, getPlayButtonStringRef } = setUpPlayBtn(ref(false))
    let { togglefullscreen } = setUpFullScreen(playerContainer)
    let currentAnimationTime = computed(() => {
      return animation ? (currentFrame.value * durationObj.dur.value) / animation.totalFrames : 0
    })
    let shakaSeeker = ref<HTMLInputElement>()
    let volumeSeeker = ref<HTMLInputElement>()
    let isPosterVisible = ref(true)
    let logger = injectStrict(LoggerKey)
    let getisPosterVisible = () => isPosterVisible
    let getplayerContainer = () => playerContainer
    const captionObj = SetUpCaptionService([])
    let getAudioPlayer = () => audioPlayer
    let getCanUseCaptions = () => ref(false)
    let dimentionHandlerObj = setUpDinemtionHandler(playerContainer)
    let getIsPlaying = () => isplaying
    return {
      getIsPlaying,
      captionObj,
      dimentionHandlerObj,
      getCanUseCaptions,
      getAudioPlayer,
      getPlayButtonStringRef,
      getplayerContainer,
      getisPosterVisible,
      logger,
      shakaSeeker,
      volumeSeeker,
      isPosterVisible,
      vbbottomMargin,
      playerBottomController,
      vbleftMargin,
      showControls,
      togglefullscreen,
      isplaying,
      volumeBar,
      playbtnString,
      durationObj,
      currentFrame,
      playerContainer,
      audioPlayer,
      has_audio,
      shakaContainer,
      currentAnimationTime,
      globalData,
      getseekbarCss
    }
  },
  mounted() {
    if (this.playerContainer == null) {
      this.logger.error("Player container is null")
      return
    }
    this.dimentionHandlerObj.handleResize(0)
    window.addEventListener("resize", () => this.dimentionHandlerObj.handleResize(0))
    animation = lottie.loadAnimation({
      container: this.playerContainer, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: this.video_url, // the path to the animation json
      assetsPath: this.assets_path,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        clearCanvas: false,
        progressiveLoad: true,
        hideOnTransparent: true
      }
    })
    animation.addEventListener("data_ready", () => {
      this.loadPlayer()
    })
    animation.addEventListener("enterFrame", this.enterFrame)
    animation.addEventListener("complete", this.onComplete)
    animation.addEventListener("data_failed", () => {
      this.logger.error("Loading animation failed")
    })
  },
  methods: {
    togglePlay() {
      this.isplaying ? this.pauseAnim() : this.playAnim()
    },
    onComplete() {
      this.stopAnim()
      this.showControls = true
      animation?.addEventListener("enterFrame", this.enterFrame)
    },
    enterFrame() {
      if (animation) {
        this.currentFrame = animation.currentFrame
        this.durationObj.currentTime.value = (this.currentFrame * this.durationObj.dur.value) / animation.totalFrames
      }
    },
    loadPlayer() {
      this.durationObj.dur.value = animation?.getDuration(false) ?? 0
      if (this.globalData.autoPlay) this.playAnim()
    },
    playAnim() {
      if (animation) {
        replaceclass(this.$refs.play_button_container as HTMLElement, "show", "hide")
        animation.play()
        this.isPosterVisible = false
        this.isplaying = true
        animation.addEventListener("enterFrame", this.enterFrame)
        this.playAudio()
      }

      this.vbbottomMargin = this.playerBottomController!.clientHeight * 0.5 + 18
      setTimeout(() => {
        this.vbbottomMargin = this.playerBottomController!.clientHeight * 0.5 + 18
      }, 500)
    },
    pauseAnim() {
      if (animation) {
        animation.pause()
        this.isplaying = false
        animation.removeEventListener("enterFrame", this.enterFrame)
        this.pauseAudio()
      }
    },
    stopAnim() {
      if (animation) {
        animation.stop()
        animation.removeEventListener("enterFrame", this.enterFrame)
      }
      this.isplaying = false
      this.stopAudio()
    },
    playAudio() {
      if (!this.has_audio) return
      var currTime =
        this.currentAnimationTime -
        Math.floor(this.currentAnimationTime / this.audioPlayer!.duration) * this.audioPlayer!.duration
      this.audioPlayer!.currentTime = currTime
      this.audioPlayer!.play()
    },
    pauseAudio() {
      if (!this.has_audio) return
      this.audioPlayer!.pause()
    },
    stopAudio() {
      if (!this.has_audio) return
      this.audioPlayer!.pause()
      this.audioPlayer!.currentTime = 0
    },
    onSeekerInput(seeker: HTMLInputElement | undefined) {
      var value: number = parseInt(seeker?.value ?? "0")
      var totalFrames: number = animation?.totalFrames ?? 0
      var currrentFrame: number = Math.floor((value / 100) * totalFrames)
      animation!.goToAndPlay(currrentFrame, true)
      this.enterFrame()
    }
  }
})
